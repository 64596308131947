import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

document.addEventListener('DOMContentLoaded', () => {
    function setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
    
    if (getCookie("cookieConsent") === "true") {
        initializeGTM();
        document.getElementById('cookie-popup').classList.remove('show');
    }

    document.getElementById('accept-all').addEventListener('click', function() {
        setCookie("cookieConsent", "true", 365);
        initializeGTM();
        document.getElementById('cookie-popup').classList.remove('show');
    });

    document.getElementById('decline').addEventListener('click', function() {
        document.getElementById('cookie-popup').classList.remove('show');
    });

    function initializeGTM() {
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-G-X8Q6MTMF5L');
    }

    const swiper = new Swiper('.swiper', {
        // Optional parameters
        direction: 'horizontal',
        loop: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        slideToClickedSlide: true,

        watchSlidesProgress: true,
        spaceBetween: 20,
        autoplay: {
            delay: 5000,
          },
      
        // If we need pagination
        pagination: {
          el: '.swiper-pagination',
        },
      
        // Navigation arrows
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            1200: {
               spaceBetween: 100,
            },
            1920: {
                spaceBetween: 160,
             },
         }
      
    });

    function retriggerVideos() {
        const videoContainers = document.querySelectorAll('.swiper-slide');
        videoContainers.forEach(container => {
            const video = container.querySelector('.examplevideo');
            video.pause();
            video.currentTime = 0;
        });

        const activeVideoContainer = document.querySelectorAll('.swiper-slide-active');
        activeVideoContainer.forEach(container => {
            const video = container.querySelector('.examplevideo');
            video.play();
        });
    }

    retriggerVideos();

    const progressBar = document.querySelector(".progress");
    progressBar.style.animationPlayState = "running";

    // Retrigger Animation on Slide Change

    function myEndFunction() {
        swiper.slideNext();
        progressBar.style.animation = "none";
        void progressBar.offsetWidth; // Triggers Reflow
        progressBar.style.animation = null;
    }

    // Reset Progress Bar On Slide Change

    swiper.on("slideChangeTransitionEnd", function () {
        progressBar.style.animation = "none";
        void progressBar.offsetWidth; // Triggers Reflow
        progressBar.style.animation = null;
        
        retriggerVideos();
    });

    // Pause Carousel/Progress Bar On Hover

    document.querySelectorAll(".swiper").forEach((item) => {
        item.addEventListener("mouseenter", function () {
            progressBar.style.animationPlayState = "paused";
        });
    });

    document.querySelectorAll(".swiper").forEach((item) => {
        item.addEventListener("mouseleave", function () {
            progressBar.style.animationPlayState = "running";
        });
    });

    i18next.use(LanguageDetector).init({
        supportedLngs: ['de', 'en'],
        debug: false,
        resources: {
          en: {
            translation: {
                "Cookies": "This website uses cookies",
                "WeUseCookies": "We use cookies to enhance your experience on our website, display personalized content, and analyze our traffic. For more information, please refer to our <a href='dataprivacy.html'>privacy policy</a>.",
                "Accept": "Accept",
                "Decline": "Decline",
                "heroHeadline": "Experience unique moments.",
                "heroText": "Easily place your videos onto your photos in Augmented Reality to experience special moments and share them with your friends.",
                "TryOut": "Try it out.",
                "ScanIt": "Scan the AppClip with your iPhone camera.",
                "FaceTowards": "Point your iPhone at the glace reference image.",
                "CreateOwn": "Create your own glace",
                "CreateOwnDescription": "With AppClips, glaces can be experienced without any installation. Create your own glaces and generate AppClips to easily share your special moments with others.",
                "OneApp": "One app: many possibilities.",
                "UseFor": "Use glace for any special occasion when you want to give someone an unforgettable moment.",
                "MarriageInvitation": "Wedding invitation",
                "Birthday": "Birthday",
                "Romantic": "Romantic gesture",
                "Extension": "Interactive extension",
                "Jubile": "Anniversary",
                "Surprise": "Surprise",
                "Imprint": "Imprint",
                "Privacy": "Privacy",
                "Copyright": "Copyright",
                "DataPrivacy": "Privacy Policy"
            }
          },
          de: {
            translation: {
                "Cookies": "Diese Webseite verwendet Cookies",
                "WeUseCookies": "Wir nutzen Cookies, um Ihre Erfahrung auf unserer Webseite zu verbessern, personalisierte Inhalte anzuzeigen und unseren Datenverkehr zu analysieren. Weitere Informationen finden Sie in unserer <a href='dataprivacy.html'>Datenschutzerklärung</a>.",
                "Accept": "Akzeptieren",
                "Decline": "Ablehnen",
                "heroHeadline": "Erlebe einzigartige Momente.",
                "heroText": "Platziere deine Videos ganz einfach auf deine Fotos in Augmented Reality, um besondere Momente zu erleben und mit deinen Freunden zu teilen.",
                "TryOut": "Probiere es aus.",
                "ScanIt": "Scanne das AppClip mit deiner iPhone-Kamera.",
                "FaceTowards": "Richte dein iPhone auf das glace-Referenzbild.",
                "CreateOwn": "Eigenes glace erstellen",
                "CreateOwnDescription": "Mit AppClips können glaces ganz ohne Installationen erlebt werden. Erstelle deine eigenen glaces und generiere AppClips, um deine besonderen Momente einfach mit anderen zu teilen.",
                "OneApp": "Eine App: viele Möglichkeiten.",
                "UseFor": "Nutze glace für jeden besonderen Anlass, wenn du anderen einen unvergesslichen Moment schenken möchtest.",
                "MarriageInvitation": "Hochzeitseinladung",
                "Birthday": "Geburtstag",
                "Romantic": "Romantische Geste",
                "Extension": "Interaktive Erweiterung",
                "Jubile": "Jubiläum",
                "Surprise": "Überraschung",
                "Imprint": "Impressum",
                "Privacy": "Datenschutz",
                "Copyright": "Urheberrecht",
                "DataPrivacy": "Datenschutz"
            }
          }
        }
      });

      document.getElementById('Cookies').innerHTML = i18next.t('Cookies');
      document.getElementById('WeUseCookies').innerHTML = i18next.t('WeUseCookies');
      document.getElementById('accept-all').innerHTML = i18next.t('Accept');
      document.getElementById('decline').innerHTML = i18next.t('Decline');
      document.getElementById('heroHeadline').innerHTML = i18next.t('heroHeadline');
      document.getElementById('heroText').innerHTML = i18next.t('heroText');
      document.getElementById('TryOut').innerHTML = i18next.t('TryOut');
      document.getElementById('ScanIt').innerHTML = i18next.t('ScanIt');
      document.getElementById('FaceTowards').innerHTML = i18next.t('FaceTowards');
      document.getElementById('CreateOwn').innerHTML = i18next.t('CreateOwn');
      document.getElementById('CreateOwnDescription').innerHTML = i18next.t('CreateOwnDescription');
      document.getElementById('OneApp').innerHTML = i18next.t('OneApp');
      document.getElementById('UseFor').innerHTML = i18next.t('UseFor');
      document.getElementById('MarriageInvitation').innerHTML = i18next.t('MarriageInvitation');
      document.getElementById('Birthday').innerHTML = i18next.t('Birthday');
      document.getElementById('Romantic').innerHTML = i18next.t('Romantic');
      document.getElementById('Extension').innerHTML = i18next.t('Extension');
      document.getElementById('Jubile').innerHTML = i18next.t('Jubile');
      document.getElementById('Surprise').innerHTML = i18next.t('Surprise');
      document.getElementById('Imprint').innerHTML = i18next.t('Imprint');
      document.getElementById('Privacy').innerHTML = i18next.t('Privacy');
      document.getElementById('Copyright').innerHTML = i18next.t('Copyright');
      document.getElementById('DataPrivacy').innerHTML = i18next.t('DataPrivacy');

      window.loadVotingBoard('votingboard')
});

